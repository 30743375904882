<template>
  <div>
    <div v-for="(item, index) in imgData" :key="index" class="imgItem">
      <div v-if="item.linkUrl" class="rightNow">
        <div>
          <img
            src="@/assets/img/school/nio-logo.png"
            alt=""
            class="rightNow-logo"
          />
        </div>
        <h3 class="rightNow-title">蔚来2025届实习生招聘计划</h3>
        <div class="rightNow-btn" @click="onLink(item.linkUrl)">立即投递</div>
        <!-- <div class="rightNow-inf">
          通过实习提前锁定心仪岗位 &nbsp;&nbsp;满电同行 &nbsp;&nbsp;不止热爱
        </div> -->
      </div>

      <img :src="item.imgurl" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgData: [
        {
          imgurl: require("@/assets/img/school/starPlan-01.jpeg"),
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7369091747786180891&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=&spread=CDRBT29",
        },
        {
          imgurl: require("@/assets/img/school/starPlan-02.png"),
        },
        {
          imgurl: require("@/assets/img/school/starPlan-03.jpg"),
        },
        {
          imgurl: require("@/assets/img/school/starPlan-04.jpeg"),
        },
      ],
    };
  },
  methods: {
    onLink(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="less" scoped>
.imgItem {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .rightNow {
    width: 100%;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    &-logo {
      width: 150px;
    }
    text-align: center;
    &-title {
      color: #fff;
      font-size: 56px;
      font-weight: 100;
      letter-spacing: 7px;
      margin: 50px 0 20px;
    }
    &-inf {
      color: rgb(207, 207, 212);
      margin-top: 100px;
    }
    &-btn {
      width: 180px;
      height: 50px;
      text-align: center;
      cursor: pointer;
      border-radius: 2px;
      line-height: 50px;
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      font-size: 18px;
      margin: 0 auto;
    }
    &-btn:hover {
      background: #00b3be;
      transition: background 0.3s;
    }
  }
}

.imgItem:hover {
  .overCards {
    display: block;
    transition: background 0.3s;
  }
}
</style>
